@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');



body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Bebas Neue', cursive;
  /*font-family: 'Montserrat', sans-serif;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




.app {
  background-color: #141414;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  position: relative;
  padding: 0 1rem;
  padding-top: 0rem; /* height of collapsed navbar +2rem */
  padding-bottom: 5rem;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  z-index: 99;
}

.animated {
  transition: all .6s ease;
}

.navbar img {
  height: 100%;
  max-height: 300px;
}

@keyframes float {
	0% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
	50% {
		box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
		transform: translatey(-20px);
	}
	100% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
}

.button {
  border: 1px solid #FF2A00;
	transform: skewX(0deg);
	display: flex;
	justify-content: center;
	align-items: center;
  padding: 0rem 3.3rem;
  border-top-left-radius: .0rem;
  border-bottom-right-radius: .0rem;
  z-index: 0;
  cursor: pointer;
}

.button--filled {
  background-color: #FF2A00;
}

.button--disabled {
  background-color: transparent;
  opacity: .6;
}

.button:hover {
  background-color:  #FF2A00;
}

.button > span {
  transform: skewX(0deg);
}

.hamburger-icon-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 0rem;
  padding: 0 1.3rem;
  gap: .6rem;
}

.hamburger-icon-wrapper div{
  width: 20px;
  height: 1px;
  background-color: white
}

.one {
  transform: rotate(45deg) translateY(7.5px);
}

.two {
  transform: rotate(-45deg) translateY(-7.5px);
}

.event-card {
  background-color: black;
  border-radius: 1rem;
}

.event-card img {
  width: 100%;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.event-card .event-card-content {
  padding: .3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.event-desc {
  white-space: pre-line;
  font-family: 'Montserrat', sans-serif;
}

.full-screen-nav {
  position: fixed;
  top: 0rem;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 98;
}

.full-screen-nav a{
  text-decoration: none;
  color: #fff;
}

.full-screen-nav h1{
  text-align: center;
  margin: 0;
  margin-bottom: 1.5rem;
  text-decoration: none;
}

.full-screen-nav > hr{
  border: 1px solid #555;
  width: 100%;
}

.op-0 {
  opacity: 0;
  display: none;
}

.location-info {
  margin-top: auto;
  display: flex;
  justify-content: center;
  gap: .8rem;
  padding: 1rem 1rem;
  flex-direction: column;
}

.location-info > div{
  display: flex;
  align-items: center;
  justify-content: center;
}

.location-info > div > *{
  flex: 1;
}

.location-info > div > div{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: .2rem;
  color: #bbb;
  font-size: 15px;
}

.location-info div p{
  padding: 0;
  margin: 0;
}


.nav-in-0 {
  animation: nav-in .5s ease 200ms;
  animation-fill-mode: forwards;
  opacity: 0;
}

.nav-in-1 {
  animation: nav-in .5s ease 300ms;
  animation-fill-mode: forwards;
  opacity: 0;
}

.nav-in-2 {
  animation: nav-in .5s ease 400ms;
  animation-fill-mode: forwards;
  opacity: 0;
}

.nav-in-3 {
  animation: nav-in .5s ease 500ms;
  animation-fill-mode: forwards;
  opacity: 0;
}

.nav-in-4 {
  animation: nav-in .5s ease 600ms;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes nav-in {
  0% { opacity: 0; transform: translateY(-15px);}
  100% { opacity: 1; transform: translateY(0);}
}

.friendlist-modal {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #111;
  text-align: center;
  overflow-y: scroll;
  padding: 0 1rem;
  padding-bottom: 3rem;
}

.friendlist-modal p {
  font-family: 'Montserrat', sans-serif;
}

.friendlist-modal form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.friendlist-modal form label {
  margin-top: 2rem;
  font-size: 1.3rem;
}

.friendlist-modal form input {
  background-color: transparent;
  border: 1px solid #707070;
  outline: none;
  align-self: stretch;
  padding: 1rem;
  border-radius: .9rem;
  color: white
}



fieldSet {
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 0;
}

.contactFormWrapper {
  border: none;
  padding: 1rem 0;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}

form {
  color: #fff;
}

label {
  margin-bottom: .4rem
}

input {
  padding: .3rem;
  outline: none;
  border: none;
  font-size: 16px;
  border-radius: .4rem;
}

button {
color: #fff;
font-family: 'Bebas Neue', cursive;
}

button:disabled {
  opacity: .6;
}



.afterForm {
  color: #fff;
  font-size: 1.3rem;
  margin: 1rem;
  font-weight: 800;
}

.text-desc {
  font-weight: 600;
  width: 90%;
}



.test {
  width: calc(100vw - 2rem);
  padding: 0 1rem;
  height: 100vh;
  padding-top: 1vh;
  color: white;
  position: relative;
  z-index: 2;
  position: fixed;
  display: flex;
  top: 0;
  flex-direction: column;
  justify-content: center;
  
  align-items: center;
  background-color: black;
  overflow-y: scroll;
}

.text {
  z-index: 3;
  width: 100%;
  font-size: 2rem;
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;
}

.text-2 {
  z-index: 3;
  width: 100%;
  font-size: 1.3rem;
  font-weight: 00;
  text-transform: none;
  text-align: center;
}

.scroll-animation {
  color: var(--color-white);
  font-size: 3rem;
  width: 40px;
  margin-top: 1rem !important;
  margin: auto;
  animation: scroll-animate 2s ease infinite;
  position: fixed;
  bottom: 40px;
  left: 50%;
}

@keyframes scroll-animate {
  0%   { transform:translate(-50%,-20px); opacity: 0;  }
  50%  { opacity: 1;  }
  100% { transform:translate(-50%,20px); opacity: 0; }
}